.leftSlot{
    max-width: 440px;
    padding: 40px;
}

.mentorSlotsWrapper .react-calendar__month-view__weekdays__weekday{
    padding: 16px 12px;
}

.mentorSlotsWrapper .react-calendar__navigation {
    margin-bottom: 0;
    border-bottom: none;
}

.mentorSlotsWrapper .react-calendar__navigation__label__labelText--from{
    font-size: 14px;
    color: var(--black);
}

.mentorSlotsWrapper .react-calendar__tile--now{
    background: var(--black-1);
    color: var(--white);
    border: 1px solid var(--black-1) !important;
    height: 60px;
}

.leftSlot{
    border-right: 1px solid var(--grey-3);
    height: 100%;
}

.timeSlotWrap{
    height: calc(100vh - 170px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.rightSlot{
    padding: 35px 20px;
    max-width: 260px;
}

.slotHeading{
    font-size: 14px;
    line-height: 20px;
    color: var(--black-1);
    font-family: 'Inter Regular';
}

.rightSlot .profileRadioGroup{
    margin-bottom: 0;
    max-height: calc(100vh - 280px);
    overflow: auto;
}

.rightSlot .profileRadioGroup .feedbackRadioGroup .radioBtnOuter{
    width: 100%;
}

.rightSlot .profileRadioGroup [type="radio"]:not(:checked) + label{
    text-align: center;
    background: var(--grey-3);
}

.rightSlot .profileRadioGroup [type="radio"]:checked + label{
    text-align: center;
    background: var(--black-1);
    border-color: var(--black-1);
    color: var(--white);
}



/* edit slots */

.editSlotsWrap{
    padding: 30px;
}

.Slotheading{
    font-size: 16px;
    line-height: 16px;
    font-family: 'inter regular';   
    font-weight: 500;
}

.editSlotsWrap .weeklyTable{
    margin-top: 15px;
    width: 100%;
    max-width: 655px;
    border: 1px solid var(--grey-3);
}

.editSlotsWrap .weeklyTable tr td:nth-child(1) {
    padding-left: 15px;
    width: 5%;
    padding-right: 0;
}

.editSlotsWrap .weeklyTable tr td:nth-child(2) {
    width: 5%;
    padding-right: 0;
}

.editSlotsWrap .weeklyTable tr td:nth-child(4) .pointer{
    text-align: right;
}

/* .editSlotsWrap .weeklyTable tr td:nth-child(4) .pointer svg path{
    fill: var(--primary-5);
} */

.editSlotsWrap .timeSlots{
    gap: 10px;
}

.editSlotsWrap .timeSlot{
    justify-content: flex-start;
    gap: 6px;
}

.editSlotsWrap .weeklyTable td{
    height: 53px;
}

.activeBlue{
    background: #E0EFFD;
}

.activeBlue .timeSlot input{
    border-color: var(--primary-5);
    color: var(--primary-5);
}

.editSlotsWrap .weeklyTable td{
    border-bottom: 0;
}

.slotTable .timeSlot input{
    text-align: center;
}

.slotTable tr,
.slotTable tr .timeSlot input{
    transition: 0.4s ease-in-out;
}

.slotTable tr:hover{
    background: #E0EFFD;
    transition: 0.4s ease-in-out;
}

.slotTable tr:hover .timeSlot input{
    border-color: var(--primary-5);
    color: var(--primary-5);
    transition: 0.4s ease-in-out;
}

.dateSlotTop{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    max-width: 655px;
}

.dateWrapSlots{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.dateWrapSlots .dateRangeTo{
    font-size: 14px;
    line-height: 120%;
    color: #262626;
}

.dateWrapSlots input{
    padding: 10px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    color: #737373;
}

.dateWrapSlots ::-webkit-calendar-picker-indicator {
    filter:opacity(0.5)
}

.weeklyTableWrapNew{
    max-width: 100%;
    overflow: auto;
}

.weeklyTableWrapNew::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: var(--grey-3);
}

.weeklyTableWrapNew::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: var(--grey-1);
}
.weeklyTableWrapNew::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
}

@media (max-width: 767px) {

    .blueButton{
        font-size: 14px;
        line-height: 100%;
    }

    .timeSlotWrap{
        flex-wrap: wrap;
        align-content: flex-start;
        height: calc(100vh - 130px);
    }

    .editSlotsWrap {
        padding: 30px 15px;
    }

    .dateSlotTop{
        max-width: 100%;
        flex-wrap: wrap;
        gap: 10px;
    }

    .dateWrapSlots{
        width: 100%;
    }

    .dateWrapSlots input{
        width: 100%;
    }

    .leftSlot{
        max-width: 100%;
        height: auto;
        padding: 30px 15px;
    }

    .mentorSlotsWrapper .react-calendar__tile--now{
        height: 47px;
    }

    .rightSlot {
        padding: 0 15px;
        max-width: 100%;
    }

    .weeklyTableWrapNew table td {
        min-width: 70px;
    }
}
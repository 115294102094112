.routesContainer.LoginWrapper{
    width: 100%;
}

.autheticationWrapper{
    background: rgba(224, 239, 253, 0.5);
    height: calc(100vh - 65px);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

.autheticationBox{
    max-width: 1000px;
    max-height: 610px;
    height: 100%;
    background: var(--white);
    padding: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.loginLeft{
    min-width: 420px;
}

.componentLogin,
.componentSignup,
.componentForget,
.componentConfirmPassword{
    padding: 20px;
}

.BlueTag{
    font-size: 14px;
    line-height: 120%;
    font-family: 'Inter Regular';
    color: var(--primary-5);
    background: #E0EFFD;
    padding: 8px 15px;
    border-radius: 35px;
    display: inline-block;
}

.formGroups.checkBoxWrap{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    flex-direction: row-reverse;
    max-width: max-content;
    cursor: pointer;
}

.formGroups.checkBoxWrap input{
    max-width: max-content;
    min-height: max-content;
    cursor: pointer;
}

.formGroups.checkBoxWrap label{
    margin-bottom: 0;
    cursor: pointer;
}

.formGroups.checkBoxWrap label span{
    display: none;
}

.forgetPassword{
    display: block;
    position: relative;
    color: var(--danger-5);
    font-size: 14px;
    line-height: 20px;
    font-family: 'Inter Regular';
    cursor: pointer;
}

.formOptions{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.policyWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Inter Regular';
    color: var(--black-4);
}

.policyText{
    font-size: 14px;
    line-height: 20px;
    font-family: 'Inter Regular';
    color: var(--black-4);
    cursor: pointer;
}

.formWrapper{
    min-height: 350px;
}

.lastWrapper{
    text-align: center;
}

.lastWrapper .policyText{
    color: var(--primary-5);
}

@media (max-width: 767px) {
    
    .autheticationBox{
        max-height: 100%;
        padding: 0;
        width: 100%;
        overflow: hidden;
        gap: 0;
        height: auto;
    }

    .loginLeft{
        min-width: 100%;
    }

    .loginRight{
        display: none;
    }

    .componentLogin .BlueTag{
        font-size: 12px;
    }

    .componentLogin .heading-2{
        font-size: 24px;
        margin-bottom: 40px;
    }

    .componentLogin, 
    .componentSignup, 
    .componentForget, 
    .componentConfirmPassword{
        padding: 30px 20px;
    }

    .policyWrapper{
        margin-top: 40px;
    }

    .formOptions{
        justify-content: flex-end;
    }

}
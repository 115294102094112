.ToastWrap{
    position: absolute;
    bottom: 30px;
    right: 30px;

    z-index: 999;

    font-size: 16px;
    line-height: 120%;
    color: #404040;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;


    padding: 14px 32px;


    border-radius: 10px;
    max-width: max-content;
    width: 100%;
}

.successToast{
    border: 2px solid #D7EFDF;
    background: #EBF7EF;
    display: none;
}
.successToast.active {
    display: flex;
}

.errorToast{
    border: 2px solid #FACBCB;
    background: #FDE5E5;
    visibility: hidden;
}

.errorToast.active {
    visibility: visible;
}

@media (max-width: 767px) {
    .ToastWrap{
        font-size: 14px;
        padding: 15px 15px;
        max-width: calc(100% - 40px);
        width: 100%;
        right: 20px;
    }
}